import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import LiveViewList from "../../components/LiveViewList";
import FieldLogList from "../../components/FieldLogList";
import "./index.css";
import axios, { AxiosError } from "axios";
import config, { DisplayablePlantingData, ApiPlantingData, PlantingType, PLANTING_TYPE_NAMES } from "../../config";
import { isTokenExpired, refreshBearerToken } from "../../services/auth";


const HEADER_NAME = "";

const { SYSTEMS, API_FAIL_ERROR, ACTIVEPLANTING, OPENPLANTINGS, CLOSEDPLANTINGS } = config?.api;

const Home = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isAuthenticating, dataLoaded, activePlantings, openPlantings, closedPlantings, headersWithAuth, systems, setBearerToken } =
    React.useContext(FrontEndContext);

  const [activePlantingDataRequested, setActivePlantingDataRequested] = useState<boolean>(true);
  const [logPlantingDataRequested, setLogPlantingDataRequested] = useState<boolean>(true);
  const [activePlantingData, setActivePlantingData] = useState<DisplayablePlantingData[]>();
  const [openPlantingData, setOpenPlantingData] = useState<DisplayablePlantingData[]>();
  const [closedPlantingData, setClosedPlantingData] = useState<DisplayablePlantingData[]>();




  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
    //DM removed for temp Alarm History deploy
    //Get Active Planting data
    const loadActivePlantings = async () => {
      let currentToken = headersWithAuth?.headers?.Authorization.split(" ")[1] ?? "";
      let newToken = "";

      // Refresh the token if expired
      if (isTokenExpired(currentToken)) {
        newToken = await refreshBearerToken();
        if (newToken) {
          setBearerToken(newToken);
          currentToken = newToken;
        } else {
          navigate("/login");
          return;
        }
      }

      const updatedHeadersWithAuth = {
        headers: { Authorization: `Bearer ${newToken || currentToken}` },
      };
      let systemActivePlantings: DisplayablePlantingData[] = [];
      let allSystemActivePlantings: DisplayablePlantingData[] = [];//DM May 14 concat all active plantings to be displayed.
      //debugger;
      if (activePlantingDataRequested && systems.length > 0) {
        for (let x = 0; x < systems.length; ++x) {
          try {
            const active = await axios.post<ApiPlantingData[]>(ACTIVEPLANTING, { seedSensingSystemId: systems[x].systemId }, updatedHeadersWithAuth);
            systemActivePlantings = active?.data
              ? active.data.map((planting) => convertToDisplayable(systems[x].systemId, systems[x].systemName, planting, PlantingType.Active))
              : [];
            allSystemActivePlantings = allSystemActivePlantings.concat(systemActivePlantings);
          }
          catch (error) {
            console.log(`[Plantings] ${API_FAIL_ERROR}${error as AxiosError}`);
          }
        }
        setActivePlantingDataRequested(false);
        setActivePlantingData(allSystemActivePlantings);
        //console.log("All active systems. " + allSystemActivePlantings);
      }
    };
    loadActivePlantings();

    //Get Field Log table data
    const loadLogPlantings = async () => {
      let systemOpenPlantings: DisplayablePlantingData[] = [];
      let systemClosedPlantings: DisplayablePlantingData[] = [];
      let allSystemOpenPlantings: DisplayablePlantingData[] = [];//DM May 14 concat all open plantings to be displayed.
      let allSystemClosedPlantings: DisplayablePlantingData[] = [];//DM May 14 concat all closed plantings to be displayed.
      //debugger;

      let currentToken = headersWithAuth?.headers?.Authorization.split(" ")[1] ?? "";
      let newToken = "";

      // Refresh the token if expired
      if (isTokenExpired(currentToken)) {
        newToken = await refreshBearerToken();
        if (newToken) {
          setBearerToken(newToken);
          currentToken = newToken;
        } else {
          navigate("/login");
          return;
        }
      }
      const updatedHeadersWithAuth = {
        headers: { Authorization: `Bearer ${newToken || currentToken}` },
      };
      if (logPlantingDataRequested && systems.length > 0) {
        for (let x = 0; x < systems.length; ++x) {
          try {
            const open = await axios.post<ApiPlantingData[]>(OPENPLANTINGS, { seedSensingSystemId: systems[x].systemId }, updatedHeadersWithAuth);
            systemOpenPlantings = open?.data
              ? open.data.map((planting) => convertToDisplayable(systems[x].systemId, systems[x].systemName, planting, PlantingType.Active))
              : [];
            allSystemOpenPlantings = allSystemOpenPlantings.concat(systemOpenPlantings);
            
            const closed = await axios.post<ApiPlantingData[]>(CLOSEDPLANTINGS, { seedSensingSystemId: systems[x].systemId }, updatedHeadersWithAuth);
            systemClosedPlantings = open?.data
              ? closed.data.map((planting) => convertToDisplayable(systems[x].systemId, systems[x].systemName, planting, PlantingType.Active))
              : [];
            allSystemClosedPlantings = allSystemClosedPlantings.concat(systemClosedPlantings);
          }
          catch (error) {
            console.log(`[Plantings] ${API_FAIL_ERROR}${error as AxiosError}`);
          }
        }
        setLogPlantingDataRequested(false);
        setOpenPlantingData(allSystemOpenPlantings);
        setClosedPlantingData(allSystemClosedPlantings);
        //console.log("All open plantings. " + allSystemOpenPlantings);
        //console.log("All closedPlantings. " + allSystemClosedPlantings);
      }
    };
    loadLogPlantings();

    const convertToDisplayable = (
      systemId: string,
      systemName: string,
      planting: ApiPlantingData,
      type: PlantingType
    ): DisplayablePlantingData => {
      if (!planting) {
        return undefined as unknown as DisplayablePlantingData;
      }

      const { id, range, acreage, seedWeight, updateTime } = planting;
      // calculating the `id` field as a combination of the systemId and the id of the planting
      // separated by a slash is important as this value is used as the last part of the URL that
      // is used to access the planting or the live data.
      // This is also used to ensure that the table has a unique ID that encompasses the system Id
      // and the planting id. Just in case the planting id is the same on different systems.
      const systemAndPlantingId = `${systemId}/${id}`;
      return {
        systemId,
        systemAndPlantingId,
        id,
        companyName: planting.companyName,
        systemName,
        type: PLANTING_TYPE_NAMES[type],
        farm: planting.farmName,
        field: planting.fieldName,
        variety: planting.varietyName,
        seedlot: planting.seedlotName,
        range,
        acreage,
        seedSpacing: planting.space,
        seedWeight,
        updateTime,
      };
    };
  }, [isAuthenticating, isAuthenticated, headersWithAuth, activePlantingDataRequested, systems, navigate, logPlantingDataRequested, setBearerToken]);//,[systems, headersWithAuth]

  //DM May 1 2024. Every 30 seconds active planting table.
  useEffect(()=>{
   let timerId = setInterval(()=>{
     setActivePlantingDataRequested(true);
   }, 60000);//DM May 1 2024 changed from 30 seconds(30000) to 60 (60000)
   return () => {
     clearInterval(timerId);
   }
 },[]);
 
 //DM May 4 2024. Every 30 min active planting table.
 useEffect(()=>{
   let timerId = setInterval(()=>{
     setActivePlantingDataRequested(true);
   }, 900000);//15 min timer
   return () => {
     clearInterval(timerId);
   }
 },[]);

  return (//DM April 15 2024. Added hideAlarmButton={true} to hide Alarm History button for release. Remove hideAlarmButton={true} when ready to release Alarm History
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating} hideAlarmButton={false}>
      <div className="home-container">
        <div className="live-view-container py-3">
          <LiveViewList dataLoaded={dataLoaded} activePlantings={activePlantingData} />
        </div>
        <div className="field-log-container">
          <FieldLogList
            dataLoaded={dataLoaded}
            activePlantings={activePlantingData}
            openPlantings={openPlantingData}
            closedPlantings={closedPlantingData}
            headersWithAuth={headersWithAuth}
          />
        </div>
      </div>
    </Page>
  );
};

export default Home;
