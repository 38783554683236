const BSSI_GREEN = "#41bc6a";
const BSSI_YELLOW = "#f3b32a";
const BSSI_RED = "#b11c32";
const BSSI_ORANGE = "#cb582f";
const BSSI_BLACK = "#000000";

const COLOR_PLANTED = BSSI_BLACK;
const COLOR_IDEAL = BSSI_GREEN;
const COLOR_NOT_IDEAL = BSSI_YELLOW;
const COLOR_DOUBLES = BSSI_ORANGE;
const COLOR_MISSES = BSSI_RED;

export { COLOR_PLANTED, COLOR_IDEAL, COLOR_NOT_IDEAL, COLOR_DOUBLES, COLOR_MISSES };
