import React, { useState } from "react";
import Select, { OptionsType } from "react-select";
import { XAxis, YAxis, CartesianGrid, ResponsiveContainer, Bar, BarChart, Legend } from "recharts";
import { COLOR_IDEAL, COLOR_NOT_IDEAL, COLOR_DOUBLES, COLOR_MISSES } from "../../colors";

interface SelectionMetaData {
  value: string;
  label: string;
  color: string;
}

const options: SelectionMetaData[] = [
  { value: "Misses", label: "Misses", color: COLOR_MISSES },
  { value: "Doubles", label: "Doubles", color: COLOR_DOUBLES },
  { value: "Not Ideal", label: "Not Ideal", color: COLOR_NOT_IDEAL },
  { value: "Ideal", label: "Ideal", color: COLOR_IDEAL },
];

export default function PlantingSummaryGraph(props: { data: any[] }) {
  const { data } = props;
  const [selectedOptions, setSelectedOptions] = useState<OptionsType<SelectionMetaData>>([
    options[0],
    options[1],
    options[2],
    options[3],
  ]);
  const selectedOptionsMap: { [id: string]: boolean } = {};
  for (const selectedOption of selectedOptions) {
    selectedOptionsMap[selectedOption.value] = true;
  }
  return (
    <>
      <div style={{ width: "100%", height: 10 }}> </div>
      <Select
        options={options}
        isMulti={true}
        onChange={(values) => setSelectedOptions(values)}
        defaultValue={selectedOptions}
      />
      <div style={{ width: "100%", height: 10 }}> </div>

      <div style={{ width: "100%", height: 328 }}>
        <ResponsiveContainer>
          <BarChart
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Legend verticalAlign="top" />
            {options.map((option) =>
              selectedOptionsMap[option.value] ? (
                <Bar key={`graph-bar-${option.value}`} dataKey={option.value} fill={option.color} />
              ) : null
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}
