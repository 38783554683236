interface DataRowInfo {
  prop: string;
  checked: boolean;
  primary: string;
}

interface ChartColumnDataTitlesComponentProps {
  visibleDataRowsConfig: DataRowInfo[];
}

interface ChartColumnDataComponentProps {
  visibleDataRowsConfig: DataRowInfo[];
  speed: string;
  misses: string;
  doubles: string;
  notIdeal: string;
  ideal: string;
  wheelAverageSpacing: string; //DM Jan 19 24
  wheelAlarmLevel: number; //DM Jan 21 24
  skipAlarmLevel: number; //DM Jan 21 24
}
//DM Jan 21 24
const DATA_PROP_SPEED = "speed";
const DATA_PROP_MISSES = "misses";

// Function used to format the labels for the data rows as well as the values
// for the data rows for each wheel.
// If there is a props parameter passed then this is an indication that the
// function is formatting a set of data values. If there is no props then the
// function formats labels for the data rows.
function formatDataRows(
  props: ChartColumnDataComponentProps | ChartColumnDataTitlesComponentProps,
  isLabel: boolean
): JSX.Element {
  const { visibleDataRowsConfig } = props;

  const wheelAlarmLevel = (props as ChartColumnDataComponentProps).wheelAlarmLevel || 0;//DM Jan 21 24
  const missesAlarmLevel = (props as ChartColumnDataComponentProps).skipAlarmLevel || 0;//DM Jan 21 24
  const labelContainer = "d-flex w-100 align-items-center py-0 pe-3 m-0";
  const labelText = "my-0 mr-2 ml-0 w-100 data-label text-end small";
  const valueContainer = "d-flex w-100 align-items-center p-0 m-0";
  const valueText = "m-0 w-100 data-value rounded text-center border";
  const valueTextAlarm = wheelAlarmLevel === 1 ? "m-0 w-100 rounded text-center border data-value-caution" 
  : "m-0 w-100 rounded text-center border data-value-warning" || missesAlarmLevel === 1 ? "m-0 w-100 rounded text-center border data-value-warning" : "" ; //DM Jan 21 24

  const dataRows: JSX.Element[] = [];
  const dataRowHeight = (100.0 / visibleDataRowsConfig.length).toFixed(1);
  const dataRowHeightStyle = { height: `${dataRowHeight}%` };

  visibleDataRowsConfig.forEach((info: DataRowInfo) => {
    const value = isLabel ? info.primary : (props as any)[info.prop];
    const showAlarm = (!isLabel && wheelAlarmLevel > 0 && info.prop === DATA_PROP_SPEED) ||
    (!isLabel && missesAlarmLevel > 0 && info.prop === DATA_PROP_MISSES);//DM Jan 21 24
    dataRows.push(
      <div className={isLabel ? labelContainer : valueContainer} key={info.primary} style={dataRowHeightStyle}>
        <div className={isLabel ? labelText : showAlarm ? valueTextAlarm : valueText}>{value}</div>
      </div>
    );
  });

  return <div className="h-100 w-100 py-1 px-0 m-0">{dataRows}</div>;
}

export function ChartColumnDataTitlesComponent(props: ChartColumnDataTitlesComponentProps) {
  return formatDataRows(props, true);
}

export function ChartColumnDataComponent(props: ChartColumnDataComponentProps) {
  return formatDataRows(props, false);
}
