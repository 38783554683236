import React from "react";

interface ScaleComponentProps {
  numDisplayedTicksInclusive: number;
  minDisplayedPercentage: number;
}

export function ScaleComponent(props: ScaleComponentProps) {
  const { numDisplayedTicksInclusive, minDisplayedPercentage } = props;

  // We need numDisplayedTicksInclusive - 1 div tags to display the ticks
  // needed for the scale component as long as we use the encompassing div to
  // display the top and bottom ticks.
  const numDivs = numDisplayedTicksInclusive - 1;
  const tickHeight = (100.0 / numDivs).toFixed(1);
  const tickDivs = [];

  const labelIncrement = Math.ceil((100.0 - minDisplayedPercentage) / numDivs);
  const labels = [];
  for (let i = 0; i < numDivs; i++) {
    if (i !== 0) {
      tickDivs.push(
        <div className="row m-0 border-bottom" style={{ height: `${tickHeight}%` }} key={`tick-${i}`}></div>
      );
    }
    labels.push(
      <div
        className="row m-0 justify-content-end align-text-bottom small"
        style={{ height: `${tickHeight}%`, lineHeight: 0, position: "relative", top: "-7px" }}
        key={`label-${i}`}
      >
        {(100.0 - labelIncrement * i).toFixed(0)}
      </div>
    );
  }
  labels.push(
    <div
      className="row m-0 mb-2 justify-content-end align-text-bottom small"
      style={{ height: 0, lineHeight: 0, position: "relative", top: "-9px" }}
      key="label-end"
    >
      {minDisplayedPercentage.toFixed(0)}
    </div>
  );

  return (
    <div className="container p-0">
      <div className="row m-0 h-100 py-2">
        <div className="col-2 p-0"></div>
        <div className="col-4 p-0">{labels}</div>
        <div className="col-1 p-0"></div>
        <div className="col-4 p-0 border-top border-bottom border-end">{tickDivs}</div>
        <div className="col-1 p-0"></div>
      </div>
    </div>
  );
}
