const SERVER_HOST = process.env.REACT_APP_API;
const SERVER_SCHEME = SERVER_HOST?.startsWith("localhost") ? "http" : "https";
const SERVER_URL = `${SERVER_SCHEME}://${SERVER_HOST}`;
const API_URL = `${SERVER_URL}/nxt/system`;

const PLANTING = `${API_URL}/planting`;
const PLANTINGS = `${API_URL}/plantings`;
//const FRONTENDSTATE = `${API_URL}/FrontendState`;
const OPENPLANTINGS = `${PLANTINGS}/open`;
const CLOSEDPLANTINGS = `${PLANTINGS}/closed`;
const ACTIVEPLANTING = `${PLANTINGS}/active`;

const STATUS = `System/Status`;
const LIVECOUNTS = `nxt/Sensors/LiveCounts`;
const PLANTINGCOUNTS = `Planting/Counts`;

//const FRONTENDSTATE = `nxt/system/FrontEndState`;
const FRONTENDSTATE = `FrontendState`;
const ALARMLIST = `${SERVER_URL}/alarms/AlarmList`;
const CSVPLANTINGDATAEXPORT = `${SERVER_URL}/company/data`;

const API_FAIL_ERROR = "Could not fetch data from server";

const SYSTEMS = `${SERVER_URL}/systems`;
const SYSTEM = `${SERVER_URL}/system`;
const getSystemIdURL = (systemId: string) => `${SERVER_URL}/system/${systemId}`;
const getPlantingInfoURL = (systemId: string, plantingId: number) =>
  `${getSystemIdURL(systemId)}/${PLANTINGCOUNTS}/${plantingId}`;
const getSystemStatusURL = (systemId: string) => `${getSystemIdURL(systemId)}/${STATUS}`;
const getLiveCountsURL = (systemId: string) => `${getSystemIdURL(systemId)}/${LIVECOUNTS}`;
const getFrontEndStateURL = () => `${SYSTEM}/${FRONTENDSTATE}`;
const getAlarmListURL = () => ALARMLIST;
const getCSVPlantingExport = () => CSVPLANTINGDATAEXPORT;

const config = {
  s3: {
    REGION: "ca-central-1",
    BUCKET: "config-bucket-325591102945-ca-central-1",
  },
  apiGateway: {
    REGION: "ca-central-1",
    URL: process.env.REACT_APP_API,
  },
  cognito: {
    REGION: "ca-central-1",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: "None",
  },
  api: {
    SYSTEMS,
    PLANTING,
    OPENPLANTINGS,
    CLOSEDPLANTINGS,
    ACTIVEPLANTING,
    PLANTINGCOUNTS,
    API_FAIL_ERROR,
    STATUS,
    LIVECOUNTS,
    FRONTENDSTATE,//DM February 1. Get frontendsate
    ALARMLIST,//DM February 23. Get Alarm List
    getPlantingInfoURL,
    getSystemStatusURL,
    getLiveCountsURL,
    getFrontEndStateURL,//DM February 1. Get frontendsate
    getAlarmListURL,//DM February 23. Get Alarm List
    getCSVPlantingExport,//DM June 24. CSV planting export
  },
};

export enum PlantingType {
  Active,
  Open,
  Closed,
}

export const PLANTING_TYPE_NAMES = {
  [PlantingType.Active]: "Active",
  [PlantingType.Open]: "Open",
  [PlantingType.Closed]: "Closed",
};

export interface ApiPlantingData {
  id: number;
  companyName: string;
  farmName: string;
  fieldName: string;
  varietyName: string;
  seedlotName: string;
  range: number;
  acreage: number;
  space: number;
  seedWeight: number;
  updateTime: string;
  apUpdateTime?: string; // active plantings only
}

export interface PlantingData extends ApiPlantingData {
  type: PlantingType;
}

export interface DisplayablePlantingData {
  systemId: string;
  systemAndPlantingId: string;
  id: number;
  companyName: string;
  systemName: string;
  type: string;
  farm: string;
  field: string;
  variety: string;
  seedlot: string;
  range: number;
  acreage: number;
  seedSpacing: number;
  seedWeight: number;
  updateTime: string;
}


export interface SystemStatusData {
  seeding: boolean;
  lowSpeed: boolean;
  currentSpeedMph: number;
  averageSpacingInches: number;
  acreage: number;
  targetSpacing: number;
  cWeightPerAcre: number;
  soilTemperature: number;//DM Jan 19 2024 added soilTemperature
  tpvMode: number;
}

export interface WheelLiveCountsMessage {
  message: string;
}

export interface WheelLiveCountsData {
  status: number;
  wheelNumber: number;
  wheelSpeed: number;
  ideal: number;
  idealPercent: number;
  notIdeal: number;
  notIdealPercent: number;
  doubles: number;
  doublesPercent: number;
  misses: number;
  missesPercent: number;
  distance: number;
  outOfRange: boolean;
  wheelPressure: number;
  staticPressureAlarm: boolean;
  lowWheelSpeed: boolean;
  batteryStateOfCharge: number;
  batteryStateOfChargeAlarm: boolean;
  successPercent: number;
  //successPercentRow: number;
  wheelAverageSpacing: number;//DM added Jan 19 2024
  wheelSpeedAlarmLevel: number; //DM Jan 21 24
  skipAlarmLevel: number; //DM Jan 21 24
  
}

//DM February 1. FrontEndData matches what is set in the trator
export interface FrontEndData {
  sliderPosition: number;
  dataRowsInfo: DataRowInfo[];
  savedPlantingInfo: Object;//DM February 1 changed to Object becuase SavedPlantingInfo uses SelectionOption not used by this application.
}

interface DataRowInfo {
  prop: string;
  checked: boolean;
  primary: string;
  showPercent: boolean;
  showToggleSwitch: boolean;
}

//DM added February 5. Show alerts
/*export interface DisplayAlarmList {
  alarmList: AlarmList[];
}*/
//Changed AlarmList to DisplayAlarmList
export interface DisplayAlarmList {
  id: number;
  wheelNumber: number;
  alarmTypeId: number;
  alarmLevel: number;
  alarmTime: string;
}

export interface CsvDownloadUrl {
  logsUrl: any;
}

export default config;
