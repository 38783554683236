const DATE_LOCALE = "default";
const DATE_FORMAT_OPTIONS: any = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "numeric",
  second: "numeric",//DM April 15 added second option
  hour12: true,
  timeZoneName: "short",
};

export const formatTime = (time: string) => new Date(time).toLocaleString(DATE_LOCALE, DATE_FORMAT_OPTIONS);
