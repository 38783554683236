// src/services/auth.ts
import { Auth } from 'aws-amplify';
import { Base64 } from 'js-base64';

/**
 * Refresh the Cognito ID token using the refresh token.
 * @returns The refreshed JWT token as a string.
 */
export const refreshBearerToken = async (): Promise<string> => {
  try {
    // Retrieve the current session, which will refresh the tokens
    const session = await Auth.currentSession();
    const idToken = session.getIdToken().getJwtToken();
    return idToken;
  } catch (error) {
    console.error('Error refreshing session with currentSession:', error);
    
    // Attempt to get the current authenticated user to renew tokens
    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = await Auth.userSession(user);
      const idToken = session.getIdToken().getJwtToken();
      return idToken;
    } catch (authError) {
      console.error('Error retrieving user session via currentAuthenticatedUser:', authError);
      return ''; // Token refresh failed
    }
  }
};
/**
 * Decode a JWT token payload from base64 to JSON.
 * @param token - The JWT token.
 * @returns The decoded payload as an object.
 */
const decodeTokenPayload = (token: string): any => {
  const [, payloadBase64] = token.split('.');
  return JSON.parse(Base64.decode(payloadBase64));
};

/**
 * Check if the provided JWT token has expired or is about to expire.
 * @param token - The JWT token.
 * @returns `true` if the token is expired or near expiry, otherwise `false`.
 */
export const isTokenExpired = (token: string): boolean => {
  if (!token) return true;

  const payload = decodeTokenPayload(token);
  const now = Math.floor(Date.now() / 1000);

  console.log("Token is about to expire in: ", payload.exp - now)

  // Consider it expired if less than 5 minutes remain
  return payload.exp - now < 180;
};


