import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import "./index.css";
import Select from 'react-select';
import axios from "axios";
import config, { DisplayAlarmList } from "../../config"; //DM removed , AlarmList
import MUIDataTable, { FilterType, Responsive, SelectableRows } from "mui-datatables";
import { formatTime} from "../../utils";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const { getAlarmListURL } = config?.api;

const CONFIG_STORAGE_KEY = "alarmsList";

const HEADER_NAME = "";

const emptyDataSet=[
  ["", "No Alarms", "", ""]
];
//DM Changed AlarmList to DisplayAlarmList
const emptyAlarmList: DisplayAlarmList[] =[
  {id:0,wheelNumber:0,alarmTypeId:0,alarmLevel:0,alarmTime:""},
];

const Alerts = () => {
  const navigate = useNavigate();
  const { isAuthenticated, isAuthenticating, systems, dataLoaded, headersWithAuth} = React.useContext(FrontEndContext);
  
  let selectOptions = [];
  for(var x = 0; x < systems.length; ++x){
    selectOptions[x] = {value: systems[x].systemId, label: systems[x].systemName};
  }
  
  const infoOptions = {
    setCellProps: () => ({
      align: "center",
    }),
  };

  const dateOptions = {
    customBodyRender: (value: any) => value !== "" ? formatTime(value) : "",
    setCellProps: () => ({
      align: "center",
    }),
  };
  
  const [selectedSystem, setSelectedSystem] = useState(systems[0].systemId);
  
  let tableConfig: any = null;
  try {
    tableConfig = JSON.parse(localStorage.getItem(CONFIG_STORAGE_KEY) || "");
  } catch (e) {}
    
  
  const COLUMNS = [
    //{ label: "ID", name: "id", options: { ...infoOptions, ...tableConfig?.columns[0] } },
    { label: "Wheel Number", name: "wheelNumber", options: { ...infoOptions, ...tableConfig?.columns[0] } },
    { label: "Alarm Type", name: "alarmTypeId", options: { ...infoOptions, ...tableConfig?.columns[1] } },
    { label: "Alarm Level", name: "alarmLevel", options: { ...infoOptions, ...tableConfig?.columns[2] } },
    { label: "Alarm Time", name: "alarmTime", options: { ...dateOptions, ...tableConfig?.columns[3] } },
  ];
  
  
  
  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });
  //debugger;
  const [alarmList, setAlarmList] = useState<String[][]>(emptyDataSet); 
  const [alarmListData, setAlarmListData] = useState<DisplayAlarmList[]>(emptyAlarmList);// Changed AlarmList to DisplayAlarmList
  const [alarmDataRequested, setAlarmDataRequested] = useState<boolean>(false);  

  useEffect(() => {
    if (alarmDataRequested) {
      //DM load the alarm list after alarmDataRequested is true
      let alarmListTable = [];
      const numAlarmsToShow = alarmListData.length; //DM replaced alarmListData.length. Removed 10 April 2 2024
      //DM May 14 2024 if no alarms set to empty data set.
      
      for(var i = 0; i < numAlarmsToShow; ++i){
        let alarmLevel = "";
        //DM set alarm level based on 1 for Speed 2 for Skip
        if(alarmListData[i].alarmTypeId === 1)
        {
          if(alarmListData[i].alarmLevel === 1)
          {
            alarmLevel = "Low alarm";
          }
          else if(alarmListData[i].alarmLevel === 2)
          {
            alarmLevel = "High alarm";
          }
          else{
            alarmLevel = "No alarm";
          }
        }
        if(alarmListData[i].alarmTypeId === 2){
          if(alarmListData[i].alarmLevel === 1){
            alarmLevel = "Alarm";
          }else{
            alarmLevel = "No alarm";
          }
        }
        alarmListTable[i] = [alarmListData[i].wheelNumber.toString(), alarmListData[i].alarmTypeId === 1 ? "Wheel speed alarm" : "Skip alarm", alarmLevel, alarmListData[i].alarmTime.toString()];
      }
      
      setAlarmList(alarmListTable);
      //setAlarmDataRequested(false);
      return;
    }
    
    if (!headersWithAuth) {
      return;
    }
    //Get AlarmList data
    const loadAlarmListData = async () => {
      //DM changed get to post March 6
      //debugger;
      const { data: alarmListRawData } = await axios.post<DisplayAlarmList[]>(getAlarmListURL(), {seedSensingSystemId: selectedSystem}, headersWithAuth);//DM need to remove systems[0] with selected value from system drop down
      if(alarmListRawData !== null && alarmListRawData !== undefined && alarmListRawData.length > 0){
        setAlarmDataRequested(true);
        setAlarmListData(alarmListRawData);
      }
      else{
        setAlarmList(emptyDataSet);
      }
    };
    loadAlarmListData();
  }, [systems, headersWithAuth, alarmDataRequested, alarmList, alarmListData, selectedSystem]);
  const handleChange = (selectedOption: any) => {
    //console.log("handleChange dev test", selectedOption.value);
    setAlarmDataRequested(false);
    setSelectedSystem(selectedOption.value);
  }
  //DM April 15. Every 30 seconds refresh alert table
  useEffect(()=>{
    let timerId = setInterval(()=>{
      setAlarmDataRequested(false);
    }, 30000);
    return () => {
      clearInterval(timerId);
    }
  },[]);

  const OPTIONS = {
    download: false,
    filterType: "dropdown" as FilterType,
    pagination: true,
    rowsPerPageOptions: [10, 25, 100, 200],
    print: false,
    responsive: "standard" as Responsive, //DM April 15 changed scroll to scrollFullHeight
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    storageKey: CONFIG_STORAGE_KEY,
    textLabels: {
      body: {
        noMatch: dataLoaded ? "No alert logs available." : "Loading ...",
      },
    },
  };
  //DM April 2 2024 override MuiDataTable styles.
  const getMuiTheme = () => createTheme({
    components: {
      MuiTableCell: {
        styleOverrides:{
          root: {
              padding: "8px"
          }
        }
      }
    }
  })

  return (
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating} hideAlarmButton={true}>
      <div className="home-container">
        <div>
          <Select
            defaultValue={selectOptions[0]}
            options={selectOptions}
            onChange={handleChange}
            />
        </div>
        <div><br></br></div>
        <div className="alarm-log-container">
          <div className="alarm-log-table">
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable 
                title={"Alert Logs"} 
                data={alarmList} 
                columns={COLUMNS} 
                options={OPTIONS} 
              />
            </ThemeProvider>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Alerts;
