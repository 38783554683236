import React from "react";

interface BarComponentWarningRange {
  above: number;
  below: number;
}

interface ChartColumnBarComponentProps {
  active: boolean;
  percentage: number;
  scalePercentage: number;
  warningRange: BarComponentWarningRange;
  minBarHeightPercentage: number;
  minDisplayedPercentage: number;
}

export function ChartColumnBarComponent(props: ChartColumnBarComponentProps) {
  const { active, percentage, scalePercentage, warningRange, minBarHeightPercentage, minDisplayedPercentage } = props;

  let barHeightPercentage = 0;
  let barColor = "bg-danger";

  if (active) {
    const displayedPercentageScale = 100 - minDisplayedPercentage;
    const scaledPercentageToDisplay = percentage - minDisplayedPercentage;
    barHeightPercentage = Math.max(
      (scaledPercentageToDisplay / displayedPercentageScale) * 100.0,
      minBarHeightPercentage
    );

    if (percentage >= scalePercentage + warningRange.above) {
      barColor = "success-color";
    } else if (percentage > scalePercentage - warningRange.below) {
      barColor = "warning-color";
    }
  }

  return (
    <div className="progress progress-bar-vertical">
      <div
        className={`progress-bar ${barColor}`}
        role="progressbar"
        style={{ height: `${Math.min(100, barHeightPercentage)}%`, width: "100%" }}
        aria-valuenow={percentage}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <h5>{`${percentage}%`}</h5>
      </div>
    </div>
  );
}
