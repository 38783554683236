import lost from "../../assets/images/lost.png";
import Page from "../../components/Page";
import "./index.css";

const HEADER_NAME = "";

const NotFound = () => (
  <Page name={HEADER_NAME} hideAlarmButton={true}>
    <div className="not-found-container">
      <img src={lost} className="not-found-image" alt="not found" />
      <h1 className="not-found-heading">Where were you trying to go?</h1>
      <p className="not-found-para">We cannot seem to find your page</p>
    </div>
  </Page>
);

export default NotFound;
