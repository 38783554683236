import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Auth } from "aws-amplify";
import axios, { AxiosError, AxiosResponse } from "axios";
import FrontEndContext, { FrontEndContextInterface, SystemInfo } from "./context/FrontEndContext";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import About from "./pages/About";
import Authentication from "./pages/Authentication";
import Planting from "./pages/Planting";
import Live from "./pages/Live";
import Alerts from "./pages/Alerts";//DM February 6 added to acces Alerts page
import config, { ApiPlantingData, DisplayablePlantingData, PlantingType, PLANTING_TYPE_NAMES, DisplayAlarmList } from "./config";

// Importing the Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
import { isTokenExpired, refreshBearerToken } from "./services/auth";

const { SYSTEMS, API_FAIL_ERROR, ACTIVEPLANTING, OPENPLANTINGS, CLOSEDPLANTINGS } = config?.api;

const EMPTY_SYSTEM_INFO: SystemInfo = {
  systemId: "",
  systemName: "",
};

function App() {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [bearerToken, setBearerToken] = useState("");
  const [userName, setUserName] = useState<string>("");
  const [systems, setSystems] = useState<SystemInfo[]>([]);
  const [activePlantings, setActivePlantings] = useState<DisplayablePlantingData[]>([]);
  const [openPlantings, setOpenPlantings] = useState<DisplayablePlantingData[]>([]);
  const [closedPlantings, setClosedPlantings] = useState<DisplayablePlantingData[]>([]);
  const [plantingsRequested, setPlantingsRequested] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [alarmList, setAlarmList] = useState<DisplayAlarmList[]>([]);//DM added February 5. Show alerts

  const navigate = useNavigate();

  const headersWithAuth: any = useMemo(() => {
    if (!bearerToken) {
      return undefined;
    }
    return { headers: { Authorization: `Bearer ${bearerToken}` } };
  }, [bearerToken]);

  const resetApp = () => {
    setIsAuthenticated(false);
    setIsAuthenticating(false);
    setBearerToken("");
    setSystems([]);
    setActivePlantings([]);
    setOpenPlantings([]);
    setClosedPlantings([]);
    setPlantingsRequested(false);
  };

  const handleAuthentication = useCallback(
    async (userSession: any, error?: any) => {

      if (userSession) {
        setIsAuthenticated(true);
        if (userSession && userSession.idToken && userSession.idToken.payload) {
          const idToken = userSession && userSession.idToken && userSession.idToken.payload;
          if (idToken) {
            const { "cognito:username": cognitoUserName } = idToken;
            setUserName(cognitoUserName);
          }
        }
        let currentToken = userSession.getIdToken().getJwtToken();
        if (isTokenExpired(currentToken)) {
          currentToken = await refreshBearerToken();
        }

        setBearerToken(currentToken);
      } else {
        // When not logged in, reset the app
        resetApp();

        // No user is signed in. This is not an error but is returned as an exception.
        if (error !== "No current user") {
          // If Cognito returns that the user must reset their password, we force navigation to the
          // ConfirmationCode flow to have the user request a confirmation code from Cognito and then set
          // a new password with the code.
          if (error.message && error.message.includes("Password reset required for the user")) {
            navigate("/confirmation-code");
          } else {
            setBearerToken("");
          }
        }
      }

      setIsAuthenticating(false);
    },
    [navigate]
  );

  useEffect(() => {
    async function onLoad() {
      try {
        const currentSession = await Auth.currentSession();
        handleAuthentication(currentSession);
      } catch (e) {
        handleAuthentication(null, e);
      }
    }

    onLoad();
  }, [handleAuthentication]);

  useEffect(() => {
    if (!isAuthenticated || !headersWithAuth) {
      return;
    }

    async function getSystems() {
      interface ApiSystemsData {
        systemId: string;
        systemName: string;
      }

      try {
        const systemsResponse = await axios.get<ApiSystemsData[]>(SYSTEMS, headersWithAuth);
        if (systemsResponse?.status >= 400) {
          console.log(`[Systems] ${API_FAIL_ERROR}${systemsResponse?.status}`);
        }

        const systemInfos: ApiSystemsData[] = systemsResponse?.data;
        if (systemInfos && systemInfos.length > 0) {
          setSystems(
            systemInfos.map((systemInfo) => ({
              ...EMPTY_SYSTEM_INFO,
              systemId: systemInfo.systemId,
              systemName: systemInfo.systemName,
            }))
          );
        } else {
          const errorMsg = "[Systems] No systems associated with this user.";
          console.log(errorMsg);
          alert(errorMsg);
          doLogout();
        }

        // no server access, so mark the server as being offline
      } catch (error) {
        console.log(`[Systems] ${API_FAIL_ERROR}${error}`);
      }
    }

    getSystems();
  }, [headersWithAuth, isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (systems.length === 0 || plantingsRequested) {
      return;
    }

    const convertToDisplayable = (
      systemId: string,
      systemName: string,
      planting: ApiPlantingData,
      type: PlantingType
    ): DisplayablePlantingData => {
      if (!planting) {
        return undefined as unknown as DisplayablePlantingData;
      }

      const { id, range, acreage, seedWeight, updateTime } = planting;
      // calculating the `id` field as a combination of the systemId and the id of the planting
      // separated by a slash is important as this value is used as the last part of the URL that
      // is used to access the planting or the live data.
      // This is also used to ensure that the table has a unique ID that encompasses the system Id
      // and the planting id. Just in case the planting id is the same on different systems.
      const systemAndPlantingId = `${systemId}/${id}`;
      return {
        systemId,
        systemAndPlantingId,
        id,
        companyName: planting.companyName,
        systemName,
        type: PLANTING_TYPE_NAMES[type],
        farm: planting.farmName,
        field: planting.fieldName,
        variety: planting.varietyName,
        seedlot: planting.seedlotName,
        range,
        acreage,
        seedSpacing: planting.space,
        seedWeight,
        updateTime,
      };
    };

    const getSystemPlantings = async (systemId: string, systemName: string) => {
      let systemActivePlantings: DisplayablePlantingData[] = [];
      let systemOpenPlantings: DisplayablePlantingData[] = [];
      let systemClosedPlantings: DisplayablePlantingData[] = [];

      try {
        const params = {
          seedSensingSystemId: systemId,
        };

        const active = await axios.post<ApiPlantingData[]>(ACTIVEPLANTING, params, headersWithAuth);
        const open = await axios.post<ApiPlantingData[]>(OPENPLANTINGS, params, headersWithAuth);
        const closed = await axios.post<ApiPlantingData[]>(CLOSEDPLANTINGS, params, headersWithAuth);

        const apiError = ([active, open, closed] as any as AxiosResponse[]).find((response) => response!.status >= 400);
        if (apiError) {
          console.log(`[Plantings] ${API_FAIL_ERROR}${apiError.status}`);
        }

        systemActivePlantings = active?.data
          ? active.data.map((planting) => convertToDisplayable(systemId, systemName, planting, PlantingType.Active))
          : [];

        systemOpenPlantings = open?.data
          ? open.data.map((planting) => convertToDisplayable(systemId, systemName, planting, PlantingType.Open))
          : [];

        systemClosedPlantings = closed?.data
          ? closed.data.map((planting) => convertToDisplayable(systemId, systemName, planting, PlantingType.Closed))
          : [];

        // no server access, so mark the server as being offline
      } catch (error) {
        console.log(`[Plantings] ${API_FAIL_ERROR}${error as AxiosError}`);

        // always return an object (even if it simply contains empty arrays)
      } finally {
        return { systemActivePlantings, systemOpenPlantings, systemClosedPlantings };
      }
    };

    const getAllPlantings = async () => {
      let allActivePlantings: DisplayablePlantingData[] = [];
      let allOpenPlantings: DisplayablePlantingData[] = [];
      let allClosedPlantings: DisplayablePlantingData[] = [];

      // request the plantings for each system and accrue them in the plantings arrays
      const plantingsPromises = systems.map(async (systemInfo) => {
        const plantings = await getSystemPlantings(systemInfo.systemId, systemInfo.systemName);
        allActivePlantings = allActivePlantings.concat(plantings.systemActivePlantings);
        allOpenPlantings = allOpenPlantings.concat(plantings.systemOpenPlantings);
        allClosedPlantings = allClosedPlantings.concat(plantings.systemClosedPlantings);
      });

      // Waiting for all the requests to complete allows us to optimize the number of times the
      // internal state is refreshed and screen is redrawn.
      await Promise.allSettled(plantingsPromises);

      setActivePlantings(allActivePlantings);
      setOpenPlantings(allOpenPlantings);
      setClosedPlantings(allClosedPlantings);

      setDataLoaded(true);
    };

    setPlantingsRequested(true);
    getAllPlantings();
  }, [activePlantings, closedPlantings, headersWithAuth, openPlantings, plantingsRequested, systems]);

  async function doLogin() {
    navigate("/login");
  }

  async function doLogout() {
    await Auth.signOut();
    resetApp();
    navigate("/login");
  }

  const queryClient = new QueryClient();

  const frontEndContext: FrontEndContextInterface = {
    setBearerToken,
    handleAuthentication,
    headersWithAuth,
    userName,
    setUserName,
    isAuthenticating,
    isAuthenticated,
    doLogin,
    doLogout,
    systems,
    dataLoaded,
    activePlantings,
    openPlantings,
    closedPlantings,
    alarmList,//DM added February 5. Show alerts
  };

  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <FrontEndContext.Provider value={frontEndContext}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Authentication authenticationAction="Login" />} />
            <Route
              path="/change-password-with-code"
              element={<Authentication authenticationAction="ChangePasswordWithCode" />}
            />
            <Route path="/change-password" element={<Authentication authenticationAction="ChangePassword" />} />
            <Route path="/confirmation-code" element={<Authentication authenticationAction="ConfirmationCode" />} />
            <Route
              path="/force-reset-password"
              element={<Authentication authenticationAction="ForceResetPassword" />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/planting/:systemId/:id" element={<Planting />} />
            <Route path="/live/:systemId" element={<Live />} />
            <Route path="/alerts/:systemId" element={<Alerts />} />
            <Route path="/lost" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </FrontEndContext.Provider>
      </QueryClientProvider>
    </React.StrictMode>
  );
}

export default App;
