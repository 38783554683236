import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Button, Popover, PopoverOrigin } from "@material-ui/core";

const NUDGE_AUTO_CLOSE_TIMEOUT = 5000; // ms
const timers: any = {};

export interface ValueNudgerProps {
  anchorRef?: React.RefObject<any>;
  anchorOrigin?: PopoverOrigin;
  id: string;
  onClose?: () => void;
  onNudge?: (nudgeUp: boolean) => void;
  transformOrigin?: PopoverOrigin;
  visible?: boolean;
}

export function ValueNudger(props: ValueNudgerProps) {
  const { anchorRef, anchorOrigin, onClose, onNudge: handleNudge, id, transformOrigin, visible } = props;

  const closeNudgerAndClearTimer = () => {
    clearTimeout(timers[id]);
    onClose && onClose();
    delete timers[id];
  };

  const handleTimerReset = () => {
    if (timers[id]) {
      clearTimeout(timers[id]);
      delete timers[id];
    }
    timers[id] = setTimeout(closeNudgerAndClearTimer, NUDGE_AUTO_CLOSE_TIMEOUT);
  };

  const handleClick = (event: React.MouseEvent) => {
    handleTimerReset();
    handleNudge && handleNudge(event.currentTarget.id === `${id}-up`);
  };

  // start the timer if the nudger is being created for the first time
  if (visible && !timers[id]) {
    handleTimerReset();
  }

  return (
    <Popover
      id={id}
      open={!!visible}
      anchorEl={anchorRef && anchorRef.current}
      onClose={closeNudgerAndClearTimer}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      <div className="col my-2">
        <div className="row mx-2">
          <Button className="" id={`${id}-up`} variant="contained" disabled={!handleNudge} onClick={handleClick}>
            <FontAwesomeIcon icon={faCaretUp as any} className="fa-3x" />
          </Button>
        </div>
        <div className="row my-2" style={{ maxHeight: "1px" }} />
        <div className="row mx-2">
          <Button className="" id={`${id}-down`} variant="contained" disabled={!handleNudge} onClick={handleClick}>
            <FontAwesomeIcon icon={faCaretDown as any} className="fa-3x" />
          </Button>
        </div>
      </div>
    </Popover>
  );
}
