import { useEffect, useState } from "react";
import { Link, useHref, useNavigate } from "react-router-dom";
import MUIDataTable, { FilterType, Responsive, SelectableRows } from "mui-datatables";
import { Button } from "@mui/material";
import { formatTime } from "../../utils";
import config, { DisplayablePlantingData, CsvDownloadUrl } from "../../config";
import "./index.css";
import axios from "axios";
import downloadImage from "../../assets/images/download-icon.png";


const { getCSVPlantingExport } = config?.api;

const CONFIG_STORAGE_KEY = "all-plantings";

interface FieldLogListProps {
  dataLoaded: boolean;
  activePlantings?: DisplayablePlantingData[];
  openPlantings?: DisplayablePlantingData[];
  closedPlantings?: DisplayablePlantingData[];
  headersWithAuth?: any;
}

export default function FieldLogList(props: FieldLogListProps) {
  const { dataLoaded, activePlantings, openPlantings, closedPlantings, headersWithAuth } = props;
  const navigate = useNavigate();
  const [plantings, setPlantings] = useState<DisplayablePlantingData[]>([]);

  const infoOptions = {
    setCellProps: () => ({
      align: "center",
    }),
  };

  const viewOptions = {
    filter: false,
    viewColumns: false,
    customBodyRender: (value: any) => (
      <Button variant="contained" onClick={() => navigate(`/planting/${value}`)}>
        View
      </Button>
    ),
  };

  const dateOptions = {
    customBodyRender: (value: any) => formatTime(value),
    setCellProps: () => ({
      align: "center",
    }),
  };

  const downloadCSVFile = async () => {
    //debugger;
    try {
      //Get url to CSV file
      const { data: csvUrl}  = await axios.post<CsvDownloadUrl>(getCSVPlantingExport(), {}, headersWithAuth);
      
      //Get the csv file
      const response = await axios.get(csvUrl.logsUrl, {
        headers: headersWithAuth,
        responseType: "blob",
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `FieldLogs_ ${new Date().toISOString().replace(/[^0-9]/g, '').slice(0, -3)}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error(`Error downloading the file:`);
    }
    
    /*
    const link = document.createElement('a');
    link.href = csvUrl.logsUrl;
    
    link.download = 'Planting_Data.csv';

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();
    document.body.removeChild(link);
    //console.log("download csv logic goes here. " + csvUrl.logsUrl);
    */

  }

  let tableConfig: any = null;
  try {
    tableConfig = JSON.parse(localStorage.getItem(CONFIG_STORAGE_KEY) || "");
  } catch (e) { }

  const COLUMNS = [
    { label: " ", name: "systemAndPlantingId", options: { ...viewOptions, ...tableConfig?.columns[0] } },
    { label: "Company", name: "companyName", options: { ...infoOptions, display: false, ...tableConfig?.columns[1] } },
    { label: "System", name: "systemName", options: { ...infoOptions, ...tableConfig?.columns[2] } },
    { label: "Farm", name: "farm", options: { ...infoOptions, ...tableConfig?.columns[3] } },
    { label: "Field", name: "field", options: { ...infoOptions, ...tableConfig?.columns[4] } },
    { label: "Variety", name: "variety", options: { ...infoOptions, ...tableConfig?.columns[5] } },
    { label: "Seedlot", name: "seedlot", options: { ...infoOptions, ...tableConfig?.columns[6] } },
    { label: "Acreage", name: "acreage", options: { ...infoOptions, ...tableConfig?.columns[7] } },
    { label: "Seed Spacing", name: "seedSpacing", options: { ...infoOptions, ...tableConfig?.columns[8] } },
    { label: "Seed Weight", name: "seedWeight", options: { ...infoOptions, ...tableConfig?.columns[9] } },
    { label: "Last Updated", name: "updateTime", options: { ...dateOptions, ...tableConfig?.columns[10] } },
  ];

  const OPTIONS = {
    download: false,
    filterType: "dropdown" as FilterType,
    pagination: true,
    rowsPerPageOptions: [10, 25, 100],
    print: false,
    customToolbar: () => (<img className="MuiButtonBase-root tss-10rusft-MUIDataTableToolbar-icon MuiIconButton-root MuiIconButton-sizeMedium css-78trlr-MuiButtonBase-root-MuiIconButton-root" 
        onClick={downloadCSVFile} data-testid="DownloadCSV-iconButton" aria-label="Download CSV" src={downloadImage} width="40px" height="40px">
      
    </img>),
    responsive: "standard" as Responsive,//DM April 1 changed vertical to scroll
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    sortOrder: {
      name: "updateTime",
      direction: "desc" as any,
    },
    storageKey: CONFIG_STORAGE_KEY,
    textLabels: {
      body: {
        noMatch: dataLoaded ? "No field logs available." : "Loading ...",
      },
    },
  };

  useEffect(() => {
    if (!dataLoaded) {
      return;
    }

    const plantings: DisplayablePlantingData[] = [];

    if (activePlantings) {
      for (const planting of activePlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.systemAndPlantingId === planting.systemAndPlantingId)) {
          plantings.push(planting);
        }
      }
    }

    if (openPlantings) {
      for (const planting of openPlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.systemAndPlantingId === planting.systemAndPlantingId)) {
          plantings.push(planting);
        }
      }
    }

    if (closedPlantings) {
      for (const planting of closedPlantings) {
        // Make sure that the planting is not already in the existing list
        if (!plantings.find((existing) => existing.systemAndPlantingId === planting.systemAndPlantingId)) {
          plantings.push(planting);
        }
      }
    }

    setPlantings(plantings);
  }, [dataLoaded, activePlantings, closedPlantings, openPlantings]);

  return (
    <div className="field-log-table">
      <MUIDataTable title={"Field Logs"} data={plantings} columns={COLUMNS} options={OPTIONS} />
    </div>
  );
}
